<div class="layout-topbar">
    <div class="topbar-start">
        <button #menubutton type="button" class="topbar-menubutton p-link p-trigger" (click)="onMenuButtonClick()">
            <i class="pi pi-bars"></i>
        </button>

        <app-breadcrumb class="topbar-breadcrumb"></app-breadcrumb>
    </div>

    <div class="topbar-end">
        <ul class="topbar-menu">
            <!-- <li class="ml-3">
                <button pButton pRipple type="button" icon="pi pi-palette" label="Tema" iconPos="right"
                    class="p-button-rounded p-button-outlined" style="height: 2.5rem;"
                    (click)="onConfigButtonClick()"></button>
            </li> -->
            <li *ngIf="mostrarAssinar" style="margin-left: -0.38rem;">
                <button pButton pRipple type="button" label="Assinar" iconPos="right"
                    class="p-button-rounded p-button-outlined" style="height: 2.5rem;"
                    (click)="onOpenModalAssinatura()"></button>
            </li>
            <li class="ml-2">
                <button pButton pRipple type="button" label="Falar com atendente" iconPos="right" icon="pi pi-whatsapp"
                    class="p-button-rounded p-button-outlined" style="height: 2.5rem;"
                    (click)="openWhatsApp()"></button>
            </li>
            <li class="ml-2">
                <button pButton pRipple type="button" icon="pi pi-user" class="p-button-rounded p-button-outlined"
                    (click)="onProfileButtonClick()"></button>
            </li>
        </ul>
    </div>
</div>
