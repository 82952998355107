import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user';
import { LayoutService } from '../../service/app.layout.service';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
    selector: 'app-profilemenu',
    templateUrl: './app.profilesidebar.component.html'
})
export class AppProfileSidebarComponent {
    user?: User | null;

    constructor(
        public layoutService: LayoutService,
        private authenticationService: AuthenticationService,
        private router: Router) {
        this.authenticationService.user.subscribe(x => this.user = x);
    }

    get visible(): boolean {
        return this.layoutService.state.profileSidebarVisible;
    }

    set visible(_val: boolean) {
        this.layoutService.state.profileSidebarVisible = _val;
    }

    goToConfiguracoes() {
        this.router.navigate(['/pages/configuracoes']);
        this.visible = false;
    }

    logout() {
        this.visible = false;
        this.authenticationService.logout();
    }
}
