<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 800" class="fixed left-0 top-0 min-h-screen min-w-screen" preserveAspectRatio="none">
    <rect [attr.fill]="dark ? 'var(--primary-900)' : 'var(--primary-500)'" width="1600" height="800"/>
    <path [attr.fill]="dark ? 'var(--primary-800)' : 'var(--primary-400)'" d="M478.4 581c3.2 0.8 6.4 1.7 9.5 2.5c196.2 52.5 388.7 133.5 593.5 176.6c174.2 36.6 349.5 29.2 518.6-10.2V0H0v574.9c52.3-17.6 106.5-27.7 161.1-30.9C268.4 537.4 375.7 554.2 478.4 581z"/>
    <path [attr.fill]="dark ? 'var(--primary-700)' : 'var(--primary-300)'" d="M181.8 259.4c98.2 6 191.9 35.2 281.3 72.1c2.8 1.1 5.5 2.3 8.3 3.4c171 71.6 342.7 158.5 531.3 207.7c198.8 51.8 403.4 40.8 597.3-14.8V0H0v283.2C59 263.6 120.6 255.7 181.8 259.4z"/>
    <path [attr.fill]="dark ? 'var(--primary-600)' : 'var(--primary-200)'" d="M454.9 86.3C600.7 177 751.6 269.3 924.1 325c208.6 67.4 431.3 60.8 637.9-5.3c12.8-4.1 25.4-8.4 38.1-12.9V0H288.1c56 21.3 108.7 50.6 159.7 82C450.2 83.4 452.5 84.9 454.9 86.3z"/>
    <path [attr.fill]="dark ? 'var(--primary-500)' : 'var(--primary-100)'" d="M1397.5 154.8c47.2-10.6 93.6-25.3 138.6-43.8c21.7-8.9 43-18.8 63.9-29.5V0H643.4c62.9 41.7 129.7 78.2 202.1 107.4C1020.4 178.1 1214.2 196.1 1397.5 154.8z"/>
</svg>
<p-toast key="tst"></p-toast>
<form [formGroup]="cadastroForm" (ngSubmit)="onSubmit()">    
    <div class="px-5 min-h-screen flex justify-content-center align-items-center">
        <div class="border-1 surface-border surface-card border-round py-7 px-4 md:px-7 z-1">
            <div class="mb-4">
                <div class="text-900 text-xl font-bold">Teste GRÁTIS por 14 dias</div>
                <span class="text-600 font-medium">Crie uma conta</span>
            </div>
            <div class="flex flex-column">
                <span class="w-full mb-4">
                    <input type="text" pInputText formControlName="cnpj" id="cnpj" mask="00.000.000/0000-00"
                        class="w-full md:w-25rem" placeholder="CNPJ" (ngModelChange)="onCnpjChange($event)"
                        [ngClass]="{ 'ng-invalid ng-dirty': submittedPessoa && formPessoa['cnpj'].errors}"/>
                    <div *ngIf="submittedPessoa && formPessoa['cnpj'].errors" class="invalid-feedback">
                        <div *ngIf="formPessoa['cnpj'].errors['required']">CNPJ é obrigatório
                        </div>
                    </div>
                </span>
                <span class="w-full mb-4">
                    <span class="w-full mb-4">
                        <input type="text" pInputText formControlName="nomeEmpresa" id="nomeEmpresa" 
                            class="w-full md:w-25rem" placeholder="Nome da empresa" (input)="inicialNomeEmpresaMaiuscula()"
                            [ngClass]="{ 'ng-invalid ng-dirty': submittedPessoa && formPessoa['nomeEmpresa'].errors }" />
                        <div *ngIf="submittedPessoa && formPessoa['nomeEmpresa'].errors" class="invalid-feedback">
                            <div *ngIf="formPessoa['nomeEmpresa'].errors['required']">Nome da empresa é obrigatório
                            </div>
                        </div>
                    </span>
                </span>
                <span class="w-full mb-4">
                    <span class="w-full mb-4">
                        <input class="telefone" pInputText formControlName="telefone" id="telefone" mask="(00) 0 0000-0000||(00) 0000-0000"
                            placeholder="WhatsApp ou Telefone"
                            [ngClass]="{ 'ng-invalid ng-dirty': submittedPessoa && cadastroForm.controls['telefone'].invalid }" />
                        <div *ngIf="submittedPessoa && cadastroForm.controls['telefone'].invalid" class="invalid-feedback">
                            <div *ngIf="cadastroForm.controls['telefone'].errors?.['required']">WhatsApp ou Telefone é obrigatório</div>
                        </div>
                        <div *ngIf="submittedPessoa && cadastroForm.controls['telefone'].invalid && cadastroForm.controls['telefone'].errors?.['invalidPhone']"
                            class="invalid-feedback">
                            <div>O número de whatsapp ou telefone é inválido.</div>
                        </div>
                    </span>
                </span>
                <span class="w-full mb-4">
                    <span class="w-full mb-4">
                        <input type="text" pInputText formControlName="nomeUsuarioCompleto" id="nomeUsuarioCompleto" 
                            class="w-full md:w-25rem" placeholder="Seu nome completo" (input)="inicialNomeMaiuscula()"
                            [ngClass]="{ 'ng-invalid ng-dirty': submittedPessoa && formPessoa['nomeUsuarioCompleto'].errors }" />
                        <div *ngIf="submittedPessoa && formPessoa['nomeUsuarioCompleto'].errors" class="invalid-feedback">
                            <div *ngIf="formPessoa['nomeUsuarioCompleto'].errors['required']">Seu nome completo é obrigatório
                            </div>
                        </div>
                    </span>
                </span>
                <span class="w-full mb-4">
                    <span class="w-full mb-4">
                        <input type="text" pInputText formControlName="email" id="email" 
                            class="w-full md:w-25rem" placeholder="E-mail"
                            [ngClass]="{ 'ng-invalid ng-dirty': submittedPessoa && formPessoa['email'].errors }" />
                        <div *ngIf="submittedPessoa && formPessoa['email'].errors" class="invalid-feedback">
                            <div *ngIf="formPessoa['email'].errors['required']">E-mail é obrigatório
                            </div>
                        </div>
                        <div *ngIf="submittedPessoa && cadastroForm.controls['email'].invalid && cadastroForm.controls['email'].errors?.['email']"
                            class="invalid-feedback">
                            <div>E-mail inválido.</div>
                        </div>
                    </span>
                </span>
                <span class="relative w-full mb-4">
                    <span class="w-full mb-4">
                        <p-password id="senha" formControlName="senha" placeholder="Senha" [toggleMask]="true"
                            [ngClass]="{ 'ng-invalid ng-dirty': submittedPessoa && cadastroForm.controls['senha'].invalid }">
                            <ng-template pTemplate="footer">
                                <p-divider />
                                <p class="mt-2">Sugestões</p>
                                <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                                    <li [style.color]="!cadastroForm.controls['senha'].value ? '' : (cadastroForm.controls['senha'].errors?.['passwordStrength']?.hasLowerCase === false ? 'red' : 'green')">
                                        Ao menos uma letra minúscula
                                    </li>
                                    <li [style.color]="!cadastroForm.controls['senha'].value ? '' : (cadastroForm.controls['senha'].errors?.['passwordStrength']?.hasUpperCase === false ? 'red' : 'green')">
                                        Ao menos uma letra maiúscula
                                    </li>
                                    <li [style.color]="!cadastroForm.controls['senha'].value ? '' : (cadastroForm.controls['senha'].errors?.['passwordStrength']?.hasNumeric === false ? 'red' : 'green')">
                                        Ao menos um número
                                    </li>
                                    <li [style.color]="!cadastroForm.controls['senha'].value ? '' : (cadastroForm.controls['senha'].errors?.['passwordStrength']?.hasSpecialChar === false ? 'red' : 'green')">
                                        Ao menos um símbolo (Exemplo: !, &#64;, #, $, etc.)
                                    </li>
                                    <li [style.color]="!cadastroForm.controls['senha'].value ? '' : (cadastroForm.controls['senha'].errors?.['passwordStrength']?.minLength === false ? 'red' : 'green')">
                                        Mínimo de 8 caracteres
                                    </li>
                                </ul>
                            </ng-template>
                        </p-password>                        
                        <div *ngIf="submittedPessoa && cadastroForm.controls['senha'].invalid" class="invalid-feedback">
                            <div *ngIf="cadastroForm.controls['senha'].errors?.['required']">A senha é obrigatória.</div>
                        </div>
                        <div *ngIf="submittedPessoa && cadastroForm.controls['senha'].invalid && cadastroForm.controls['senha'].errors?.['passwordStrength']?.hasLowerCase === false" class="invalid-feedback">
                            <div>Ao menos uma letra minúscula</div>
                        </div>
                        <div *ngIf="submittedPessoa && cadastroForm.controls['senha'].invalid && cadastroForm.controls['senha'].errors?.['passwordStrength']?.hasUpperCase === false" class="invalid-feedback">
                            <div>Ao menos uma letra maiúscula</div>
                        </div>
                        <div *ngIf="submittedPessoa && cadastroForm.controls['senha'].invalid && cadastroForm.controls['senha'].errors?.['passwordStrength']?.hasNumeric === false" class="invalid-feedback">
                            <div>Ao menos um número</div>
                        </div>
                        <div *ngIf="submittedPessoa && cadastroForm.controls['senha'].invalid && cadastroForm.controls['senha'].errors?.['passwordStrength']?.minLength === false" class="invalid-feedback">
                            <div>Mínimo de 8 caracteres</div>
                        </div>
                        <div *ngIf="submittedPessoa && cadastroForm.controls['senha'].invalid && cadastroForm.controls['senha'].errors?.['passwordStrength']?.hasSpecialChar === false" class="invalid-feedback">
                            <div>Ao menos um símbolo (Exemplo: !, &#64;, #, $, etc.)</div>
                        </div>    
                        <div *ngIf="submittedPessoa && senhasNaoConferem" class="invalid-feedback" [style.color]="'red'">
                            <div>As senhas não coincidem.</div>
                        </div>
                    </span>
                </span>
                <span class="relative w-full mb-4">
                    <span class="w-full mb-4">                    
                        <p-password id="asd" formControlName="senhaConfirmacao" placeholder="Confirme a Senha" [toggleMask]="true" [feedback]="false"
                        [ngClass]="{ 'ng-invalid ng-dirty': submittedPessoa && cadastroForm.controls['senha'].invalid }">
                        </p-password>
                        <div *ngIf="submittedPessoa && senhasNaoConferem" class="invalid-feedback" [style.color]="'red'">
                            <div>As senhas não coincidem.</div>
                        </div>
                    </span>
                </span>
                <button pButton pRipple label="Cadastrar" class="w-full mb-4" [disabled]="loading" [loading]="loading"></button>
                <span class="font-medium text-600">Já tem uma conta? <a class="font-semibold cursor-pointer text-900 hover:text-primary transition-colors transition-duration-300" [routerLink]="['/']">Entrar</a></span>
                <div class="margin-auth"></div>
            </div>
        </div>
    </div>
</form>

<app-config [minimal]="true"></app-config>