<p-toast key="tst"></p-toast>

<div class="card">
    <div class="mb-3" style="font-size: 1.15rem;">
        <span>Em qual método você deseja realizar este pagamento?</span>
    </div>

    <div class="flex justify-content-center">
        <div class="flex flex-wrap gap-5">
            <div class="flex align-items-center"
                (click)="selectPayment('Cartão de Crédito'); this.metodoPagamento = 'Cartão de Crédito'">
                <p-radioButton name="metodoPagamento" value="Cartão de Crédito" [(ngModel)]="metodoPagamento"
                    (onClick)="selectPayment('Cartão de Crédito')" />
                <label class="ml-2">
                    Cartão de Crédito
                </label>
            </div>

            <div class="flex align-items-center" (click)="selectPayment('PIX'); this.metodoPagamento = 'PIX'">
                <p-radioButton name="metodoPagamento" value="PIX" [(ngModel)]="metodoPagamento"
                    (onClick)="selectPayment('PIX')" />
                <label class="ml-2">
                    PIX
                </label>
            </div>

            <div class="flex align-items-center"
                (click)="selectPayment('Boleto Bancário'); this.metodoPagamento = 'Boleto Bancário'">
                <p-radioButton name="metodoPagamento" value="Boleto Bancário" [(ngModel)]="metodoPagamento"
                    (onClick)="selectPayment('Boleto Bancário')" />
                <label class="ml-2">
                    Boleto Bancário
                </label>
            </div>
        </div>
    </div>
</div>

<div *ngIf="activePayment" id="paymentDetails">
    <div *ngIf="activePayment === 'PIX'" class="p-text-center">
        <div style="text-align: center;">
            <h4>Pague com PIX</h4>
        </div>

        <div style="text-align: center;" *ngIf="gerouQRCodePix">
            <h5 *ngIf="gerouQRCodePix">Pedido aguardando pagamento</h5>
            <p>Pague com Pix QR Code ou Pix Copia e Cola:</p>
        </div>

        <div *ngIf="expiredMessageQRCodePix" class="mb-3" style="text-align: center;">
            <p style="color: red;">{{ expiredMessageQRCodePix }}</p>
        </div>

        <p-button *ngIf="!gerouQRCodePix" label="Gerar QRCode" [loading]="gerarQRCodePix" [disabled]="gerarQRCodePix"
            (click)="onGerarQRCodePix()"></p-button>

        <div *ngIf="gerouQRCodePix" id="qrCodePix" class="p-mt-3 p-d-flex p-justify-content-center"
            style="text-align: center;">
            <qrcode [qrdata]="pixQrCode" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
        </div>

        <div class="p-inputgroup" *ngIf="gerouQRCodePix">
            <input type="text" pInputText [value]="pixCopiaECola" />
            <button type="button" pButton icon="pi pi-copy" styleClass="p-button-warn"
                (click)="copyToClipboard(pixCopiaECola)"><span class="ml-2">Copiar</span></button>
        </div>

        <div class="mt-3" *ngIf="gerouQRCodePix">
            <p style="margin-bottom: -2px;">{{ formattedTime }}</p>
            <p-progressBar [value]="(totalSeconds / 300) * 100" [showValue]="false"></p-progressBar>
        </div>
    </div>

    <div class="flex justify-content-center ">
        <div *ngIf="activePayment === 'Cartão de Crédito'" class="p-text-center">
            <div style="text-align: center;">
                <h4>Pague com Cartão de Crédito</h4>
            </div>

            <form [formGroup]="cartaoDeCreditoForm">
                <div class="field">
                    <label>Número do cartão</label>
                    <input type="text" pInputText formControlName="cardNumber" mask="0000 0000 0000 0000"
                        placeholder="0000 0000 0000 0000" autofocus />
                </div>

                <div class="formgrid grid">
                    <div class="field col">
                        <label>Validade</label>
                        <input type="text" pInputText formControlName="validade" mask="00/00" placeholder="MM/AA" />
                    </div>
                    <div class="field col">
                        <label>Cód. Segurança</label>
                        <input type="text" pInputText formControlName="securityCode" mask="000" placeholder="000" />
                    </div>
                </div>

                <div class="field">
                    <label>Nome no cartão</label>
                    <input type="text" pInputText formControlName="cardholderName" placeholder="Nome no cartão" />
                </div>
                <div class="field">
                    <label>CNPJ ou CPF</label>
                    <input type="text" pInputText formControlName="identificationNumber" placeholder="CNPJ ou CPF"
                        (ngModelChange)="onCnpjCpfChange($event)" />
                </div>

                <p-button label="Pagar" (click)="efetuarPagamentoCartaoDeCredito()" [loading]="processouCartaoDeCredito"></p-button>
            </form>
        </div>
    </div>

    <div *ngIf="activePayment === 'Boleto Bancário'" class="p-text-center">
        <div style="text-align: center;">
            <h4>Pague com Boleto Bancário</h4>
        </div>

        <p-button label="Gerar Boleto Bancário" (click)="gerarBoletoBancario()" [loading]="gerouBoletoBancario"></p-button>
    </div>
</div>

<form [formGroup]="empresaForm" (ngSubmit)="onSubmitSalvar()">
    <p-dialog [(visible)]="enderecoDialog" [style]="{minWidth: '1150px'}" header="Atualize seu endereço" [modal]="true" class="p-fluid"
        [closable]="!disableSalvarEndereco">
        <ng-template pTemplate="content">
            <div class="p-formgrid grid">
                <div class="col-12 md:col-2">
                    <label>CEP</label>
                    <input pInputText type="text" formControlName="cep" id="cep" (blur)="consultarCep($event)"
                        mask="00000-000"
                        [ngClass]="{ 'ng-invalid': empresaForm.controls['cep'].invalid }" />
                        <div *ngIf="empresaForm.controls['cep'].invalid" class="invalid-feedback">
                            <div *ngIf="empresaForm.controls['cep'].errors?.['required']">CEP é obrigatório</div>
                        </div>
                </div>
                <div class="col-12 md:col-4">
                    <label>Rua</label>
                    <input type="text" pInputText formControlName="logradouro" id="logradouro"
                        [ngClass]="{ 'ng-invalid': empresaForm.controls['logradouro'].invalid }" />
                        <div *ngIf="empresaForm.controls['logradouro'].invalid" class="invalid-feedback">
                            <div *ngIf="empresaForm.controls['logradouro'].errors?.['required']">Rua é obrigatório</div>
                        </div>
                </div>
                <div class="col-12 md:col-1">
                    <label>Número</label>
                    <input type="number" pInputText formControlName="numero" id="numero" [min]="1"
                        [ngClass]="{ 'ng-invalid': empresaForm.controls['numero'].invalid }" />
                    <div *ngIf="empresaForm.controls['numero'].invalid" class="invalid-feedback">
                        <div *ngIf="empresaForm.controls['numero'].errors?.['required']">Número é obrigatório</div>
                    </div>
                </div>
                <div class="col-12 md:col-2">
                    <label>Complemento</label>
                    <input type="text" pInputText formControlName="complemento" id="complemento"
                        [ngClass]="{ 'ng-invalid': empresaForm.controls['complemento'].invalid }" />
                    <div *ngIf="empresaForm.controls['complemento'].invalid" class="invalid-feedback">
                        <div *ngIf="empresaForm.controls['complemento'].errors?.['required']">Complemento é obrigatório</div>
                    </div>
                </div>
                <div class="col-12 md:col-3">
                    <label>Bairro</label>
                    <input type="text" pInputText formControlName="bairro" id="bairro"
                        [ngClass]="{ 'ng-invalid': empresaForm.controls['bairro'].invalid }" />
                    <div *ngIf="empresaForm.controls['bairro'].invalid" class="invalid-feedback">
                        <div *ngIf="empresaForm.controls['bairro'].errors?.['required']">Bairro é obrigatório</div>
                    </div>
                </div>
                <div class="col-12 md:col-3">
                    <label>Cidade</label>
                    <input type="text" pInputText formControlName="cidade" id="cidade"
                        [ngClass]="{ 'ng-invalid': empresaForm.controls['cidade'].invalid }" />
                    <div *ngIf="empresaForm.controls['cidade'].invalid" class="invalid-feedback">
                        <div *ngIf="empresaForm.controls['cidade'].errors?.['required']">Cidade é obrigatório</div>
                    </div>
                </div>
                <div class="col-12 md:col-3">
                    <label>Estado</label>
                    <input type="text" pInputText formControlName="estado" id="estado"
                        [ngClass]="{ 'ng-invalid': empresaForm.controls['estado'].invalid }" />
                    <div *ngIf="empresaForm.controls['estado'].invalid" class="invalid-feedback">
                        <div *ngIf="empresaForm.controls['estado'].errors?.['required']">Estado é obrigatório</div>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="footer">
            <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
                (click)="hideDialogEndereco()" [disabled]="disableSalvarEndereco"></button>
            <button pButton pRipple label="Salvar" icon="pi pi-check" class="p-button-text"
                [loading]="disableSalvarEndereco"></button>
        </ng-template>

        <p-toast key="enderecoToast"></p-toast>
    </p-dialog>
</form>

<p-dialog [(visible)]="visualizarBoletoBancarioDialog" [modal]="true" [header]="'Pague o seu boleto'"
    (onHide)="hideDialogVisualizarBoletoBancario()" class="custom-dialog">
    <ng-template pTemplate="content">
        <div class="header-buttons">
            <button pButton pRipple icon="pi pi-copy" label="Copiar código de barras"
                class="p-button-outlined button-responsive mr-2 mb-2" (click)="copiarCodigoBarras()"></button>
        </div>
        <div class="card py-8 px-6 md:px-8 overflow-auto">
            <iframe [src]="urlBoletoBancarioSafe" width="100%" height="600px"></iframe>
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancelar" type="button" icon="pi pi-times" class="p-button-text"
            (click)="hideDialogVisualizarBoletoBancario()"></button>
    </ng-template>

    <p-toast key="boletoToast"></p-toast>
</p-dialog>