import { Component, ElementRef, ViewChild } from '@angular/core';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { AssinaturaWppModalService } from '../assinatura-wpp/assinatura-wpp-modal.service';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopbarComponent {
    mostrarAssinar: boolean = true;

    @ViewChild('menubutton') menuButton!: ElementRef;

    constructor(
        public layoutService: LayoutService, 
        private assinaturaWppModalService: AssinaturaWppModalService
    ) { 
        this.mostrarAssinar = (JSON.parse(sessionStorage.getItem('mostrarAssinar')!)) == true;
    }

    onMenuButtonClick() {
        this.layoutService.onMenuToggle();
    }

    onProfileButtonClick() {
        this.layoutService.showProfileSidebar();
    }
    onConfigButtonClick() {
        this.layoutService.showConfigSidebar();
    }
    
    onOpenModalAssinatura() {
        this.assinaturaWppModalService.openDialog();
    }

    openWhatsApp() {
        const url = 'https://wa.me/554799887004?text=Ol%C3%A1%2C%20quero%20falar%20com%20um%20atendente.';
        window.open(url, '_blank');
    }
}