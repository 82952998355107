import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { first } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { LayoutService } from 'src/app/layout/service/app.layout.service';

@Component({
	templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    providers: [MessageService]
})
export class LoginComponent implements OnInit {
    loginForm!: FormGroup;
    loading: boolean = false;
    submitted: boolean = false;
    showPassword: boolean = false;

	constructor(
        private layoutService: LayoutService,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private messageService: MessageService) {
        if (this.authenticationService.userValue) {
            this.router.navigate(['/']);
        }
    }

	get dark(): boolean {
		return this.layoutService.config.colorScheme !== 'light';
	}

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });
    }

    get f() { return this.loginForm.controls; }

    onSubmit() {
        this.submitted = true;

        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;
        this.authenticationService.login(this.f['username'].value, this.f['password'].value)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.loading = false;
                    this.router.navigate(['/']);
                },
                error: () => {
                    this.messageService.add({
                        key: 'tst',
                        severity: 'error',
                        summary: 'Ocorreu um erro!',
                        detail: 'Por favor, entre em contato com o administrador.',
                        life: 4000
                    });
                    this.loading = false;
                }
            });
    }

    togglePasswordVisibility() {
        this.showPassword = !this.showPassword;
    }
}
