import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import jwtDecode from 'jwt-decode';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const user = this.authenticationService.userValue;

        const accessToken = user?.accessToken ?? '';

        if (accessToken != '') {
            const decodedToken = jwtDecode(accessToken) as { exp: number };
            const exp = decodedToken.exp * 1000;
            const expirationDate = new Date(exp);
            const currentDate = new Date();
            const tokenExpired = expirationDate < currentDate;

            if (user && !tokenExpired) {
                // check if route is restricted by role
                const { roles } = route.data;
                if (roles && !roles.includes(user.role)) {
                    // role not authorized so redirect to home page
                    this.router.navigate(['/']);
                    return false;
                }

                // authorized so return true
                return true;
            }
        }

        // not logged in so redirect to login page with the return url
        localStorage.removeItem('user');
        this.router.navigate(['/login']);
        return false;
    }
}
