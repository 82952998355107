import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models/user';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private userSubject: BehaviorSubject<User | null>;
    public user: Observable<User | null>;

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.userSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('user')!));
        this.user = this.userSubject.asObservable();
    }

    public get userValue() {
        return this.userSubject.value;
    }

    login(username: string, password: string) {
        return this.http.post<any>(`${environment.apiUrl}/usuario/login`, { email: username, senha: password })
            .pipe(map(user => {
                localStorage.setItem('user', JSON.stringify(user));
                this.userSubject.next(user);
                return user;
            }));
    }

    logout() {
        localStorage.removeItem('user');
        this.userSubject.next(null);
        this.router.navigate(['/login']);
    }

    trocarSenha(senhaAtual: string, senhaNova: string, senhaConfirmacao: string) {
        const usuario = JSON.parse(localStorage.getItem('user')!).usuario;

        return this.http.post<any>(`${environment.apiUrl}/usuario/trocar-senha`, { email: usuario, senhaAtual, senhaNova: senhaNova, senhaConfirmacao: senhaConfirmacao });
    }

    esqueceuSenha(email: string) {
        return this.http.post<any>(`${environment.apiUrl}/usuario/esqueceu-senha`, { email: email });
    }
}
