import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from './layout/app.layout.component';
import { AuthGuard } from './helpers/auth.guard';
import { LoginComponent } from './auth/login/login.component';
import { ForgotPasswordComponent } from './auth/forgotpassword/forgotpassword.component';
import { RegisterComponent } from './auth/register/register.component';

const routerOptions: ExtraOptions = {
    anchorScrolling: 'enabled'
};

const routes: Routes = [
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: 'register',
        component: RegisterComponent,
    },
    {
        path: 'esqueci-minha-senha',
        component: ForgotPasswordComponent,
    },
    {
        path: '', component: AppLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: '', data: { breadcrumb: 'Início' }, loadChildren: () => import('./components/inicio/inicio.module').then(m => m.InicioModule) },
            { path: 'pages', data: { breadcrumb: 'Pages' }, loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule) },
        ]
    },
    { path: 'auth', data: { breadcrumb: 'Auth' }, loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
    { path: 'notfound', loadChildren: () => import('./components/notfound/notfound.module').then(m => m.NotfoundModule) },
    { path: '**', redirectTo: '/notfound' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
