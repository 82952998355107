<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 800" class="fixed left-0 top-0 min-h-screen min-w-screen" preserveAspectRatio="none">
    <rect [attr.fill]="dark ? 'var(--primary-900)' : 'var(--primary-500)'" width="1600" height="800"/>
    <path [attr.fill]="dark ? 'var(--primary-800)' : 'var(--primary-400)'" d="M478.4 581c3.2 0.8 6.4 1.7 9.5 2.5c196.2 52.5 388.7 133.5 593.5 176.6c174.2 36.6 349.5 29.2 518.6-10.2V0H0v574.9c52.3-17.6 106.5-27.7 161.1-30.9C268.4 537.4 375.7 554.2 478.4 581z"/>
    <path [attr.fill]="dark ? 'var(--primary-700)' : 'var(--primary-300)'" d="M181.8 259.4c98.2 6 191.9 35.2 281.3 72.1c2.8 1.1 5.5 2.3 8.3 3.4c171 71.6 342.7 158.5 531.3 207.7c198.8 51.8 403.4 40.8 597.3-14.8V0H0v283.2C59 263.6 120.6 255.7 181.8 259.4z"/>
    <path [attr.fill]="dark ? 'var(--primary-600)' : 'var(--primary-200)'" d="M454.9 86.3C600.7 177 751.6 269.3 924.1 325c208.6 67.4 431.3 60.8 637.9-5.3c12.8-4.1 25.4-8.4 38.1-12.9V0H288.1c56 21.3 108.7 50.6 159.7 82C450.2 83.4 452.5 84.9 454.9 86.3z"/>
    <path [attr.fill]="dark ? 'var(--primary-500)' : 'var(--primary-100)'" d="M1397.5 154.8c47.2-10.6 93.6-25.3 138.6-43.8c21.7-8.9 43-18.8 63.9-29.5V0H643.4c62.9 41.7 129.7 78.2 202.1 107.4C1020.4 178.1 1214.2 196.1 1397.5 154.8z"/>
</svg>
<div class="px-5 min-h-screen flex justify-content-center align-items-center">
    <p-toast key="tst"></p-toast>
    <div class="border-1 surface-border surface-card border-round py-7 px-4 md:px-7 z-1">
        <div class="md-3 mb-4 flex justify-content-center">
            <img src="../../../assets/layout/images/logo-azul.png" width="150">
        </div>
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <div class="mb-4">
                <div class="text-900 text-xl font-bold mb-2">Login</div>
                <span class="text-600 font-medium">Insira os seus dados de acesso</span>
            </div>
            <div class="flex flex-column">
                <span class="p-input-icon-left w-full mb-4">
                    <i class="pi pi-envelope"></i>
                    <input id="email" type="text" pInputText formControlName="username" class="w-full md:w-25rem" placeholder="E-mail"
                        [ngClass]="{ 'ng-dirty ng-invalid': submitted && f['username'].errors }" />
                </span>
                <span *ngIf="submitted && f['username'].errors" class="p-input-icon-left w-full -mt-4 invalid-feedback">
                    <div *ngIf="f['username'].errors['required']">E-mail é obrigatório</div>
                </span>
                <span class="p-input-icon-left w-full mb-4">
                    <i class="pi pi-lock"></i>
                    <p-password id="password" formControlName="password" class="w-full md:w-25rem" placeholder="Senha" [toggleMask]="true" [ngClass]="{ 'ng-dirty ng-invalid': submitted && f['password'].errors }" [feedback]="false">
                    </p-password>
                </span>
                <span *ngIf="submitted && f['password'].errors" class="p-input-icon-left w-full -mt-4 invalid-feedback">
                    <div *ngIf="f['password'].errors['required']">Senha é obrigatório</div>
                </span>
                
                <button pButton pRipple label="Entrar" class="w-full" [routerLink]="['/']" [disabled]="loading" [loading]="loading"></button>
                <div class="mb-3 mt-4" style="text-align: center;"> 
                    <span class="font-medium text-600"><a class="font-semibold cursor-pointer text-900 hover:text-primary transition-colors transition-duration-300" [routerLink]="['/esqueci-minha-senha']">Esqueceu a senha?</a></span>
                </div>
                <div class="mb-4">
                    <span class="font-medium text-600 ">Não possui cadastro? <a class="font-semibold cursor-pointer text-900 hover:text-primary transition-colors transition-duration-300" [routerLink]="['/register']">Assine agora.</a></span>
                </div>
                <div class="margin-auth"></div>
            </div>
        </form>
    </div>
</div>

<!-- <div class="px-5 min-h-screen flex justify-content-center align-items-center">
    <div class="border-1 surface-border surface-card border-round py-7 px-4 md:px-7 z-1">
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <div class="mb-4">
                <div class="text-900 text-xl font-bold mb-2">Login</div>
                <span class="text-600 font-medium">Insira os seus dados de acesso</span>
            </div>
            <div class="flex flex-column">
                <span class="p-input-icon-left w-full mb-4">
                    <i class="pi pi-envelope"></i>
                    <input id="email" type="text" pInputText formControlName="username" class="w-full md:w-25rem" placeholder="E-mail"
                        [ngClass]="{ 'ng-dirty ng-invalid': submitted && f['username'].errors }" />
                </span>
                <span *ngIf="submitted && f['username'].errors" class="p-input-icon-left w-full -mt-4 invalid-feedback">
                    <div *ngIf="f['username'].errors['required']">E-mail é obrigatório</div>
                </span>
                <span class="p-input-icon-left w-full mb-4">
                    <i class="pi pi-lock"></i>
                    <input id="password" 
                           [type]="showPassword ? 'text' : 'password'" 
                           pInputText 
                           formControlName="password" 
                           class="w-full md:w-25rem" 
                           placeholder="Senha" 
                           [ngClass]="{ 'ng-dirty ng-invalid': submitted && f['password'].errors }" />
                    <i class="pi" 
                       [ngClass]="showPassword ? 'pi-eye' : 'pi-eye-slash'" 
                       (click)="togglePasswordVisibility()"
                       style="position: absolute; right: 1rem; top: 50%; transform: translateY(-50%); margin-top: 1px; color: #64748b;">
                    </i>
                </span>
                <span *ngIf="submitted && f['password'].errors" class="p-input-icon-left w-full -mt-4 invalid-feedback">
                    <div *ngIf="f['password'].errors['required']">Senha é obrigatório</div>
                </span>
                <button pButton pRipple label="Entrar" class="w-full" [routerLink]="['/']" [disabled]="loading"></button>
                <div class="mb-4 mt-4 flex flex-wrap gap-3">
                    <span class="font-medium text-600"><a class="font-semibold cursor-pointer text-900 hover:text-primary transition-colors transition-duration-300" [routerLink]="['/esqueci-minha-senha']">Esqueceu a senha?</a></span>
                </div>
                <div class="mb-4 mt-4 flex flex-wrap gap-3">
                    <span class="font-medium text-600 text-900 font-medium mr-7 ">Não possui cadastro? <a class="font-semibold cursor-pointer text-900 hover:text-primary transition-colors transition-duration-300" [routerLink]="['/register']">Assine agora.</a></span>
                </div>
            </div>
        </form>       
    </div>
</div> -->

<app-config [minimal]="true"></app-config>
