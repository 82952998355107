import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { AssinaturaModalService } from './assinatura-modal.service';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'assinatura',
  templateUrl: './assinatura.component.html',
  styleUrls: ['./assinatura.component.scss'],
})
export class AssinaturaComponent implements OnInit {
  dialog: boolean = false;
  items!: MenuItem[];
  showForm: boolean = true;

  constructor(private assinaturaModalService: AssinaturaModalService) { }

  ngOnInit() {
    this.assinaturaModalService.dialogVisible$.subscribe(visible => {
      this.items = [
        { label: 'Step 1' },
        { label: 'Step 2' },
        { label: 'Step 3' }
      ];

      this.dialog = visible;
    });
  }

  hideDialog() {
    this.showForm = true;
    this.assinaturaModalService.closeDialog();
  }

  openWhatsAppFaleComUmConsultor() {
    const url = 'https://wa.me/554799887004?text=Ol%C3%A1%2C%20quero%20falar%20com%20um%20consultor%20sobre%20Plano%20Personalizado.';
    window.open(url, '_blank');
  }

  onAssinar() {
    this.showForm = false;
  }

  onVoltar() {
    this.showForm = true;
  }
}