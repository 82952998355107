import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InputTextModule } from 'primeng/inputtext';
import { SidebarModule } from 'primeng/sidebar';
import { BadgeModule } from 'primeng/badge';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TooltipModule } from 'primeng/tooltip';
import { RippleModule } from 'primeng/ripple';
import { AppConfigModule } from './config/app.config.module';
import { AppLayoutComponent } from './app.layout.component';
import { AppBreadcrumbComponent } from './components/breadcrumb/app.breadcrumb.component';
import { AppTopbarComponent } from './components/top-bar/app.topbar.component';
import { AppMenuComponent } from './components/menu/app.menu.component';
import { AppMenuitemComponent } from './components/menu/app.menuitem.component';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { AssinaturaComponent } from './components/assinatura/assinatura.component';
import { AssinaturaWppComponent } from './components/assinatura-wpp/assinatura-wpp.component';
import { AppProfileSidebarComponent } from './components/profile-side-bar/app.profilesidebar.component';
import { AppSidebarComponent } from './components/side-bar/app.sidebar.component';
import { DialogModule } from 'primeng/dialog';
import { StepsModule } from 'primeng/steps';
import { CardModule } from 'primeng/card';
import { AssinarComponent } from './components/assinatura/assinar/assinar.component';
import { QRCodeModule } from 'angularx-qrcode';
import { ProgressBarModule } from 'primeng/progressbar';
import { ToastModule } from 'primeng/toast';
import { InputMaskModule } from 'primeng/inputmask';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
    declarations: [
        AppLayoutComponent,
        AppBreadcrumbComponent,
        AppSidebarComponent,
        AppTopbarComponent,
        AppProfileSidebarComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AssinaturaComponent,
        AssinaturaWppComponent,
        AssinarComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        InputTextModule,
        SidebarModule,
        BadgeModule,
        RadioButtonModule,
        InputSwitchModule,
        ButtonModule,
        TooltipModule,
        RippleModule,
        RouterModule,
        AppConfigModule,
        ProgressSpinnerModule,
        DialogModule,
        StepsModule,
        CardModule,
        QRCodeModule,
        InputTextModule,
        ProgressBarModule,
        ReactiveFormsModule,
        ToastModule,
        InputMaskModule,
        NgxMaskModule.forRoot(),
    ]
})
export class AppLayoutModule { }
