import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { AuthenticationService } from './services/authentication.service';
import { Role } from './models/role';
import { User } from './models/user';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
    user?: User | null;

    constructor(private primengConfig: PrimeNGConfig, private authenticationService: AuthenticationService) {
        this.authenticationService.user.subscribe(x => this.user = x);
    }

    ngOnInit(): void {
        this.primengConfig.ripple = true;

        this.primengConfig.setTranslation({
            weak: 'Fraca',
            medium: 'Média',
            strong: 'Forte',
            passwordPrompt: 'Digite sua senha'
        });
    }

    get isAdmin() {
        return this.user?.role === Role.Admin;
    }

    logout() {
        this.authenticationService.logout();
    }
}
