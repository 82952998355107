import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ConfirmationService, MessageService } from 'primeng/api';
import { User } from 'src/app/models/user';
import { AssinaturaService } from 'src/app/services/assinatura.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { EmpresasService } from 'src/app/services/empresas.service';
import { ViaCepService } from 'src/app/services/viacep.service';

declare const MercadoPago: any;
const mp = new MercadoPago('APP_USR-ea6b7f67-c1c0-4d64-afcc-588c8d18c040');

@Component({
  selector: 'assinar',
  templateUrl: './assinar.component.html',
  styleUrls: ['./assinar.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class AssinarComponent implements OnInit {
  cartaoDeCreditoForm!: FormGroup;
  activePayment: string | null = null;
  gerarQRCodePix: boolean = false;
  gerouQRCodePix: boolean = false;
  totalSeconds: number = 300;
  pixQrCode!: string;
  pixCopiaECola!: string;
  interval: any;
  expiredMessageQRCodePix: string | null = null;
  formattedTime: string = this.formatTime(this.totalSeconds);
  metodoPagamento!: string;
  user?: User | null;
  paymentMessage!: string;
  empresaForm!: FormGroup;
  enderecoDialog: boolean = false;
  disableSalvarEndereco: boolean = false;
  submittedEndereco: boolean = false;
  visualizarBoletoBancarioDialog: boolean = false;
  gerouBoletoBancario: boolean = false;
  urlBoletoBancarioSafe!: SafeResourceUrl;
  codigoBarras!: string;
  processouCartaoDeCredito: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private assinaturaService: AssinaturaService,
    private authenticationService: AuthenticationService,
    private empresasService: EmpresasService,
    private viaCepService: ViaCepService,
    private sanitizer: DomSanitizer

  ) {
    this.cartaoDeCreditoForm = this.formBuilder.group({
      cardholderName: '',
      cardNumber: '',
      validade: '',
      cardExpirationMonth: '',
      cardExpirationYear: '',
      securityCode: '',
      installments: '',
      identificationType: '',
      identificationNumber: '',
      paymentMethodId: ''
    });

    this.empresaForm = this.formBuilder.group({
      id: [''],
      nome: [''],
      razaoSocial: [''],
      cnpj: [''],
      telefone: [''],
      email: [''],
      inscricaoEstadual: [''],
      inscricaoMunicipal: [''],
      cep: ['', Validators.required],
      logradouro: ['', Validators.required],
      numero: [null, Validators.required],
      complemento: ['', Validators.required],
      bairro: ['', Validators.required],
      cidade: ['', Validators.required],
      estado: ['', Validators.required],
      imgLogo: ['']
    });

    this.authenticationService.user.subscribe(x => this.user = x);
  }

  ngOnInit() {
    const script = document.createElement('script');
    script.src = 'https://www.mercadopago.com/v2/security.js';
    script.setAttribute('view', 'item');
    script.async = true;

    // Adiciona o script ao body
    document.body.appendChild(script);

    // this.assinaturaService.onPaymentStatusReceived((message: string) => {
    //   this.paymentMessage = message;
    // });


    const savedPaymentMethod = localStorage.getItem('activePayment');
    if (savedPaymentMethod) {
      this.activePayment = savedPaymentMethod;
      this.metodoPagamento = this.activePayment;
    }

    const savedGerarQRCodePix = localStorage.getItem('gerarQRCodePix');
    const savedGerouQRCodePix = localStorage.getItem('gerouQRCodePix');
    if (savedGerarQRCodePix) {
      this.gerarQRCodePix = savedGerarQRCodePix === 'true';
    }
    if (savedGerouQRCodePix) {
      this.gerouQRCodePix = savedGerouQRCodePix === 'true';
    }

    const savedTotalSeconds = localStorage.getItem('totalSeconds');
    const savedStartTime = localStorage.getItem('qrCodeStartTime');
    if (savedTotalSeconds && savedStartTime) {
      const startTime = new Date(savedStartTime).getTime();
      const currentTime = new Date().getTime();
      const elapsedTime = Math.floor((currentTime - startTime) / 1000);
      this.totalSeconds = Math.max(0, parseInt(savedTotalSeconds) - elapsedTime);
      this.formattedTime = this.formatTime(this.totalSeconds);

      if (this.totalSeconds <= 0) {
        this.handleExpiration();
      } else {
        this.startCountdown();
      }
    }

    setTimeout(() => {
      this.scrollToPaymentDetails();
    }, 100);
  }

  get formEmpresa() {
    return this.empresaForm.controls;
  }

  selectPayment(paymentMethod: string) {
    this.activePayment = paymentMethod;
    localStorage.setItem('activePayment', paymentMethod);

    setTimeout(() => {
      this.scrollToPaymentDetails();
    }, 100);
  }

  scrollToPaymentDetails() {
    const element = document.getElementById('paymentDetails');
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }, 50);
    }
  }

  onGerarQRCodePix() {
    this.gerarQRCodePix = true;

    this.empresasService.obterPorEmpresaId().subscribe({
      next: (data) => {
        this.empresaForm.patchValue({
          id: data.data.id,
          nome: data.data.nome,
          razaoSocial: data.data.razaoSocial,
          cnpj: data.data.cnpj,
          telefone: data.data.telefone,
          email: data.data.email,
          inscricaoEstadual: data.data.inscricaoEstadual,
          inscricaoMunicipal: data.data.inscricaoMunicipal,
          cep: data.data.cep,
          logradouro: data.data.logradouro,
          numero: data.data.numero,
          complemento: data.data.complemento,
          bairro: data.data.bairro,
          cidade: data.data.cidade,
          estado: data.data.estado,
          imgLogo: data.data.imgLogo
        });

        if (
          !data.data.cep ||
          !data.data.logradouro ||
          !data.data.numero ||
          !data.data.complemento ||
          !data.data.bairro ||
          !data.data.cidade ||
          !data.data.estado
        ) {
          this.enderecoDialog = true;
        } else {
          this.assinaturaService.gerarQRCodePix().subscribe({
            next: (data) => {
              console.log(data);

              this.pixQrCode = data.data.pixQrCode;
              this.pixCopiaECola = data.data.pixEmv;
              this.gerarQRCodePix = false;
              this.gerouQRCodePix = true;
              this.totalSeconds = 300;
              this.expiredMessageQRCodePix = null;
              this.formattedTime = this.formatTime(this.totalSeconds);

              localStorage.setItem('pixQrCode', this.pixQrCode);
              localStorage.setItem('pixCopiaECola', this.pixCopiaECola);
              localStorage.setItem('gerarQRCodePix', 'true');
              localStorage.setItem('gerouQRCodePix', 'true');
              localStorage.setItem('totalSeconds', String(this.totalSeconds));
              localStorage.setItem('qrCodeStartTime', new Date().toISOString());

              this.startCountdown();

              setTimeout(() => {
                this.scrollToPaymentDetails();
              }, 100);
            },
            error: () => {
              this.gerarQRCodePix = false;
              this.messageService.add({
                key: 'tst',
                severity: 'error',
                summary: 'Ocorreu um erro!',
                detail: 'Por favor, entre em contato com o administrador.',
                life: 4000,
              });
            },
          });
        }
      },
      error: () => {
        this.messageService.add({
          key: 'tst',
          severity: 'error',
          summary: 'Ocorreu um erro!',
          detail: 'Por favor, entre em contato com o administrador.',
          life: 4000,
        });
      },
    });
  }

  startCountdown() {
    this.interval = setInterval(() => {
      this.totalSeconds--;
      this.formattedTime = this.formatTime(this.totalSeconds);

      if (this.totalSeconds <= 0) {
        clearInterval(this.interval);
        this.totalSeconds = 0;
        this.handleExpiration();
      }
    }, 1000);
  }

  handleExpiration() {
    this.gerarQRCodePix = false;
    this.gerouQRCodePix = false;
    this.expiredMessageQRCodePix = 'O QR Code expirou. Por favor, gere um novo QR Code.';
  }

  formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  }

  copyToClipboard(data: string) {
    navigator.clipboard.writeText(data);
  }

  // Cartão de crédito

  onCnpjCpfChange(event: any) {
    const maskedValue = this.applyCnpjCpfMask(event);

    if (this.cartaoDeCreditoForm.get('identificationNumber')?.value !== maskedValue) {
      this.cartaoDeCreditoForm.patchValue({
        identificationNumber: maskedValue
      }, { emitEvent: false });
    }
  }

  applyCnpjCpfMask(value: any): string {
    value = value.replace(/\D/g, '');

    if (value.length <= 11) {
      return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else if (value.length <= 14) {
      return value.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        '$1.$2.$3/$4-$5'
      );
    }

    return value;
  }

  efetuarPagamentoCartaoDeCredito() {
    this.processouCartaoDeCredito = true;

    const identificationNumber = this.cartaoDeCreditoForm.get('identificationNumber')!.value;
    let numeroCnpjCpf;

    numeroCnpjCpf = identificationNumber.replace(/[./-]/g, '');

    const validade = this.cartaoDeCreditoForm.get('validade')!.value;
    const month = validade.substring(0, 2);
    const year = validade.substring(2, 4);

    const paymentPayload = {
      number: this.cartaoDeCreditoForm.get('cardNumber')!.value,
      cvv: this.cartaoDeCreditoForm.get('securityCode')!.value,
      month: month,
      year: year,
      documentNumber: numeroCnpjCpf,
      name: this.cartaoDeCreditoForm.get('cardholderName')!.value
    };

    this.assinaturaService.processarPagamentoCartaoDeCredito(paymentPayload).subscribe({
      next: (data) => {
        this.messageService.add({
          key: 'tst',
          severity: data.data.status == "approved" ? 'success' : 'warn',
          summary: data.data.message,
          life: 4000,
        });
      },
      error: () => {
        this.processouCartaoDeCredito = false;
        this.messageService.add({
          key: 'tst',
          severity: 'error',
          summary: 'Ocorreu um erro!',
          detail: 'Por favor, entre em contato com o administrador.',
          life: 4000,
        });
      },
    });
  }

  //Boleto bancário

  gerarBoletoBancario() {
    this.gerouBoletoBancario = true;

    if (this.urlBoletoBancarioSafe != null) {
      this.visualizarBoletoBancarioDialog = true;
      this.gerouBoletoBancario = false;
    } else {
      this.empresasService.obterPorEmpresaId().subscribe({
        next: (data) => {
          this.empresaForm.patchValue({
            id: data.data.id,
            nome: data.data.nome,
            razaoSocial: data.data.razaoSocial,
            cnpj: data.data.cnpj,
            telefone: data.data.telefone,
            email: data.data.email,
            inscricaoEstadual: data.data.inscricaoEstadual,
            inscricaoMunicipal: data.data.inscricaoMunicipal,
            cep: data.data.cep,
            logradouro: data.data.logradouro,
            numero: data.data.numero,
            complemento: data.data.complemento,
            bairro: data.data.bairro,
            cidade: data.data.cidade,
            estado: data.data.estado,
            imgLogo: data.data.imgLogo
          });
  
          if (
            !data.data.cep ||
            !data.data.logradouro ||
            !data.data.numero ||
            !data.data.complemento ||
            !data.data.bairro ||
            !data.data.cidade ||
            !data.data.estado
          ) {
            this.enderecoDialog = true;
            this.gerouBoletoBancario = false;
          } else {
            this.assinaturaService.gerarBoletoBancario().subscribe({
              next: (data) => {  
                this.gerouBoletoBancario = false;
                window.open(data.data.url, '_blank');
                this.codigoBarras = data.data.codigoBarras;
                this.visualizarBoletoBancarioDialog = true;
              },
              error: () => {
                this.gerouBoletoBancario = false;
                
                this.messageService.add({
                  key: 'boletoToast',
                  severity: 'error',
                  summary: 'Ocorreu um erro!',
                  detail: 'Por favor, entre em contato com o administrador.',
                  life: 4000,
                });
              },
            });
          }
        },
        error: () => {
          this.messageService.add({
            key: 'boletoToast',
            severity: 'error',
            summary: 'Ocorreu um erro!',
            detail: 'Por favor, entre em contato com o administrador.',
            life: 4000,
          });
        },
      });
    }
  }

  consultarCep(cep: any) {
    this.viaCepService.getEndereco(cep.target.value).subscribe({
      next: (data) => {
        this.empresaForm.patchValue({
          logradouro: data.logradouro,
          bairro: data.bairro,
          cidade: data.localidade,
          estado: data.uf,
        });
      },
      error: () => {
        this.messageService.add({
          key: 'enderecoToast',
          severity: 'error',
          summary: 'Ocorreu um erro!',
          detail: 'Por favor, entre em contato com o administrador.',
          life: 4000,
        });
      },
    });
  }

  hideDialogEndereco() {
    this.empresaForm.reset();
    this.enderecoDialog = false;
    this.submittedEndereco = false;
  }

  onSubmitSalvar() {
    this.disableSalvarEndereco = true;

    if (this.empresaForm.invalid) {
      this.disableSalvarEndereco = false;
      return;
    }

    this.empresasService.salvar(this.empresaForm.value).subscribe({
      next: () => {
        this.disableSalvarEndereco = false;
        this.enderecoDialog = false;
        this.messageService.add({
          key: 'enderecoToast',
          severity: 'success',
          summary: 'Sucesso!',
          detail: 'Empresa salva.',
          life: 4000
        });
      },
      error: () => {
        this.disableSalvarEndereco = false;
        this.messageService.add({
          key: 'enderecoToast',
          severity: 'error',
          summary: 'Ocorreu um erro!',
          detail: 'Por favor, entre em contato com o administrador.',
          life: 4000,
        });
      },
    });
  }

  hideDialogVisualizarBoletoBancario() {
    this.visualizarBoletoBancarioDialog = false;
  }

  copiarCodigoBarras() {
    const codigo = this.codigoBarras;
    navigator.clipboard.writeText(codigo).then(() => {
      this.messageService.add({
        key: 'boletoToast',
        severity: 'success',
        summary: 'Código copiado!',
        detail: 'O código de barras foi copiado para a área de transferência.',
        life: 3000,
      });
    }).catch(err => {
      this.messageService.add({
        key: 'boletoToast',
        severity: 'error',
        summary: 'Erro ao copiar',
        detail: 'Ocorreu um erro ao tentar copiar o código de barras.',
        life: 3000,
      });
    });
  }
}
