<!-- <button class="layout-config-button p-link" type="button" (click)="onConfigButtonClick()">
    <i class="pi pi-palette"></i>
</button> -->

<p-sidebar [(visible)]="visible" position="right" [transitionOptions]="'.3s cubic-bezier(0, 0, 0.2, 1)'" styleClass="layout-config-sidebar w-18rem">
    <h5>Cor principal</h5>
    <div class="flex flex-wrap row-gap-3">
        <div class="w-3" *ngFor="let theme of componentThemes">
            <button type="button"
                class="cursor-pointer p-link w-2rem h-2rem border-circle flex-shrink-0 flex align-items-center justify-content-center"
                (click)="changeTheme(theme.name)"
                [ngStyle]="{'background-color': theme.color}"><i *ngIf="theme.name == this.layoutService.config.theme " class="pi pi-check text-white"></i></button>
        </div>
    </div>

    <h5>Tamanho</h5>
    <div class="flex align-items-center">
        <button icon="pi pi-minus" type="button" pButton (click)="decrementScale()" class="p-button-text p-button-rounded w-2rem h-2rem mr-2" [disabled]="scale === scales[0]"></button>
        <div class="flex gap-2 align-items-center">
            <i class="pi pi-circle-fill text-300" *ngFor="let s of scales" [ngClass]="{'text-primary-500': s === scale}"></i>
        </div>
        <button icon="pi pi-plus"  type="button" pButton (click)="incrementScale()" class="p-button-text p-button-rounded w-2rem h-2rem ml-2" [disabled]="scale === scales[scales.length - 1]"></button>
    </div>

    <ng-container *ngIf="!minimal">
        <h5>Tipo do menu</h5>
        <div class="flex flex-wrap row-gap-3">
            <div class="flex align-items-center gap-2 w-6">
                <p-radioButton name="menuMode" value="static" [(ngModel)]="menuMode" inputId="mode1"></p-radioButton>
                <label for="mode1">Estático</label>
            </div>
            <div class="flex align-items-center gap-2 w-6">
                <p-radioButton name="menuMode" value="overlay" [(ngModel)]="menuMode" inputId="mode2"></p-radioButton>
                <label for="mode2">Sobreposição</label>
            </div>
            <div class="flex align-items-center gap-2 w-6">
                <p-radioButton name="menuMode" value="slim" [(ngModel)]="menuMode" inputId="mode3"></p-radioButton>
                <label for="mode3">Fino</label>
            </div>
            <div class="flex align-items-center gap-2 w-6">
                <p-radioButton name="menuMode" value="slim-plus" [(ngModel)]="menuMode" inputId="mode4"></p-radioButton>
                <label for="mode3">Fino +</label>
            </div>
            <div class="flex align-items-center gap-2 w-6">
                <p-radioButton name="menuMode" value="reveal" [(ngModel)]="menuMode" inputId="mode6"></p-radioButton>
                <label for="mode5">Revelar</label>
            </div>
            <div class="flex align-items-center gap-2 w-6">
                <p-radioButton name="menuMode" value="drawer" [(ngModel)]="menuMode" inputId="mode7"></p-radioButton>
                <label for="mode6">Gaveta</label>
            </div>
            <div class="flex align-items-center gap-2 w-6">
                <p-radioButton name="menuMode" value="horizontal" [(ngModel)]="menuMode" inputId="mode5"></p-radioButton>
                <label for="mode4">Horizontal</label>
            </div>
        </div>

        <h5>Esquema de cor do menu</h5>
        <div class="field-radiobutton">
            <p-radioButton name="menuTheme" value="colorScheme" [(ngModel)]="menuTheme" inputId="menutheme-colorscheme"></p-radioButton>
            <label for="menutheme-colorscheme">Padrão</label>
        </div>
        <div class="field-radiobutton">
            <p-radioButton name="menuTheme" value="primaryColor" [(ngModel)]="menuTheme" inputId="menutheme-primarycolor"></p-radioButton>
            <label for="menutheme-primarycolor">Primária (com base cor principal)</label>
        </div>
        <div class="field-radiobutton">
            <p-radioButton name="menuTheme" value="transparent" [(ngModel)]="menuTheme" inputId="menutheme-transparent"></p-radioButton>
            <label for="menutheme-transparent">Transparente</label>
        </div>
    </ng-container>

    <h5>Esquema de cor do sistema</h5>
    <div class="field-radiobutton">
        <p-radioButton name="colorScheme" value="light" [(ngModel)]="colorScheme" inputId="mode-light"></p-radioButton>
        <label for="mode-light">Claro</label>
    </div>
    <div class="field-radiobutton">
        <p-radioButton name="colorScheme" value="dim" [(ngModel)]="colorScheme" inputId="mode-dim"></p-radioButton>
        <label for="mode-dim">Escuro pouco iluminado</label>
    </div>
    <div class="field-radiobutton">
        <p-radioButton name="colorScheme" value="dark" [(ngModel)]="colorScheme" inputId="mode-dark"></p-radioButton>
        <label for="mode-dark">Escuro</label>
    </div>
</p-sidebar>
