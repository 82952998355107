import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { MenuService } from './app.menu.service';
import { MessageService } from 'primeng/api';
import { AssinaturaService } from 'src/app/services/assinatura.service';
import { Permissao } from 'src/app/models/permissao';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
    providers: [MenuService, MessageService]
})
export class AppMenuComponent implements OnInit {

    model: any[] = [];
    permissao: Permissao = {
        mensagem: '',
        assinaturaVencida: false,
        bloquearAcesso: false,
        mostraBotaoAssinar: true
    };

    constructor(
        private menuService: MenuService,
        private messageService: MessageService,
        private assinaturaService: AssinaturaService
    ) {
    }

    ngOnInit() {
        this.menuService.listar().subscribe({
            next: (data) => {
                this.model = [
                    {
                        label: 'Início',
                        icon: 'pi pi-home',
                        items: [
                            {
                                label: 'Início',
                                icon: 'pi pi-fw pi-home',
                                routerLink: ['/']
                            }
                        ]
                    },
                ]
                this.model = [
                    ...this.model,
                    ...data
                ];
            },
            error: () => {
                this.messageService.add({
                    key: 'tst',
                    severity: 'error',
                    summary: 'Ocorreu um erro!',
                    detail: 'Por favor, entre em contato com o administrador.',
                    life: 4000,
                });
            },
        });

        this.permissaoUsuario();
    }

    permissaoUsuario() {
        this.assinaturaService.permissao().subscribe({
            next: (data) => {
                this.permissao = data.data
            },
            error: (erro) => {
                this.messageService.add({
                    key: 'tst',
                    severity: 'error',
                    summary: 'Ocorreu um erro!',
                    detail: erro,
                    life: 5000,
                });
            },
        });
    }
}
