import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class EmpresasService {
    constructor(private http: HttpClient) { }

    assinaturaCadastro14DiasGratis(request: any) {
        return this.http.post<any>(`${environment.apiUrl}/assinatura/assinatura-cadastro-14-dias-gratis`, request);
    }

    obterPorEmpresaId() {
        return this.http.get<any>(`${environment.apiUrl}/empresas/obter-por-empresaid`);
    }

    salvar(request: any) {
        return this.http.post<any>(`${environment.apiUrl}/empresas`, request);
    }
}
