import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as signalR from '@microsoft/signalr';

@Injectable({ providedIn: 'root' })
export class AssinaturaService {
    //private hubConnection: signalR.HubConnection;

    constructor(private http: HttpClient) {
        // this.hubConnection = new signalR.HubConnectionBuilder()
        //     .withUrl('https://geactio-mercadopagonotifier-hml-gjhxc2adayf5buhx.eastus2-01.azurewebsites.net/paymentHub') // URL do seu Hub
        //     .build();

        // this.hubConnection.start()
        //     .catch(err => console.error('Error while starting SignalR connection: ' + err));
    }

    gerarQRCodePix() {
        return this.http.post<any>(`${environment.apiUrl}/assinatura/gerar-qr-code-pix`, {});
    }

    processarPagamentoCartaoDeCredito(request: any) {
        return this.http.post<any>(`${environment.apiUrl}/assinatura/processar-pagamento-cartao-de-credito`, request);
    }

    gerarBoletoBancario() {
        return this.http.post<any>(`${environment.apiUrl}/assinatura/gerar-boleto-bancario`, {});
    }

    // public onPaymentStatusReceived(callback: (message: string) => void): void {
    //     this.hubConnection.on('ReceivePaymentStatus', callback);
    // }
    
    permissao(){
        return this.http.post<any>(`${environment.apiUrl}/assinatura/permissao-usuario`, {});
    }
}
