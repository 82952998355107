import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { AssinaturaWppModalService } from './assinatura-wpp-modal.service';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'assinatura-wpp',
  templateUrl: './assinatura-wpp.component.html',
  styleUrls: ['./assinatura-wpp.component.scss'],
})
export class AssinaturaWppComponent implements OnInit {
  dialog: boolean = false;
  items!: MenuItem[];
  showForm: boolean = true;

  constructor(private assinaturaWppModalService: AssinaturaWppModalService) { }

  ngOnInit() {
    this.assinaturaWppModalService.dialogVisible$.subscribe(visible => {
      this.items = [
        { label: 'Step 1' },
        { label: 'Step 2' },
        { label: 'Step 3' }
      ];

      this.dialog = visible;
    });
  }

  hideDialog() {
    this.showForm = true;
    this.assinaturaWppModalService.closeDialog();
  }

  openWhatsAppFaleComUmConsultor() {
    const url = 'https://wa.me/5547997920165?text=Ol%C3%A1%2C%20quero%20falar%20com%20um%20consultor%20sobre%20Plano%20Personalizado.';
    window.open(url, '_blank');
  }

  openWhatsAppReceberFaturaAssinatura() {
    const url = 'https://wa.me/5547997920165?text=Ol%C3%A1%2C%20quero%20assinar%20o%20Plano%20Start%20do%20Geactio.';
    window.open(url, '_blank');
  }

  onAssinar() {
    this.showForm = false;
  }

  onVoltar() {
    this.showForm = true;
  }
}