<p-dialog [(visible)]="dialog" [header]="'Assinatura'" [modal]="true" (onHide)="hideDialog()" [style]="{maxWidth:'700px'}">
    <ng-template pTemplate="content">
        <div class="p-fluid">
            <div class="p-formgrid grid" *ngIf="showForm">
                <div class="col-12 md:col-6">
                    <p-card header="Plano Start" subheader="R$ 59,90 /mês" styleClass="p-card-shadow custom-hover" (click)="onAssinar()">
                        <p>3 usuários</p>
                        <h6>Todas as funcionalidades:</h6>
                        <ul>
                            <li>Dashboard Operacional</li>
                            <li>Dashboard Financeiro</li>
                            <li>Cadastro de Clientes ou Fornecedores</li>
                            <li>Cadastro de Produtos</li>
                            <li>Cadastro de Serviços</li>
                            <li>Orçamentos</li>
                            <li>Pedidos de Venda</li>
                            <li>Ordens de Serviço</li>
                            <li>Pedidos de Compra</li>
                            <li>Controle de Estoque</li>
                            <li>Controle Financeiro</li>
                            <li>Relatórios</li>
                        </ul>
                        <p><strong>Suporte:</strong> Premium</p>
                        <ng-template pTemplate="footer">
                            <p-button label="Assinar" icon="pi pi-check" (click)="onAssinar()"></p-button>
                        </ng-template>
                    </p-card>
                </div>
                <div class="col-12 md:col-6">
                    <p-card header="Plano Personalizado" subheader="R$ sob demanda /mês" styleClass="p-card-shadow custom-hover" (click)="openWhatsAppFaleComUmConsultor()">
                        <p>Usuários ilimitados</p>
                        <h6>Todas as funcionalidades:</h6>
                        <ul>
                            <li>Dashboard Operacional</li>
                            <li>Dashboard Financeiro</li>
                            <li>Cadastro de Clientes ou Fornecedores</li>
                            <li>Cadastro de Produtos</li>
                            <li>Cadastro de Serviços</li>
                            <li>Orçamentos</li>
                            <li>Pedidos de Venda</li>
                            <li>Ordens de Serviço</li>
                            <li>Pedidos de Compra</li>
                            <li>Controle de Estoque</li>
                            <li>Controle Financeiro</li>
                            <li>Relatórios</li>
                        </ul>
                        <p><strong>Suporte:</strong> Premium</p>
                        <ng-template pTemplate="footer">
                            <p-button label="Falar com um consultor" icon="pi pi-comments" (click)="openWhatsAppFaleComUmConsultor()"></p-button>
                        </ng-template>
                    </p-card>
                </div>
            </div>

            <div *ngIf="!showForm" style="max-width: 500px;">
                <!-- <h5>Você assinou o plano com sucesso!</h5>
                <p>Obrigado por se inscrever! Um e-mail de confirmação foi enviado para você.</p>
                Aqui você pode adicionar mais informações ou opções, se necessário -->

                <assinar></assinar>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancelar" type="button" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
    </ng-template>
</p-dialog>
