import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { CnpjService } from 'src/app/services/cnpj.service';
import { EmpresasService } from 'src/app/services/empresas.service';

function passwordValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;

    if (!value) {
        return null;
    }

    const hasUpperCase = /[A-Z]/.test(value);
    const hasLowerCase = /[a-z]/.test(value);
    const hasNumeric = /[0-9]/.test(value);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(value);
    const minLength = value.length >= 8;

    const errors: any = {};
    
    if (!hasUpperCase) {
        errors.hasUpperCase = false;
    }
    if (!hasLowerCase) {
        errors.hasLowerCase = false;
    }
    if (!hasNumeric) {
        errors.hasNumeric = false;
    }
    if (!hasSpecialChar) {
        errors.hasSpecialChar = false;
    }
    if (!minLength) {
        errors.minLength = false;
    }

    return Object.keys(errors).length === 0 ? null : { passwordStrength: errors };
}

export function telefoneValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value?.replace(/\D/g, '');

    if (!value) {
        return { required: true };
    }

    const validLength = value.length === 10 || value.length === 11;

    return validLength ? null : { invalidPhone: true };
}

@Component({
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.scss'],
	providers: [MessageService]
})
export class RegisterComponent {
	cadastroForm!: FormGroup;
	submittedPessoa: boolean = false;
	showPassword: boolean = false;
	loading: boolean = false;
    senhasNaoConferem: boolean = false;

	constructor(
		private messageService: MessageService,
		private layoutService: LayoutService, 
		private router: Router,
		private formBuilder: FormBuilder,
		private empresasService: EmpresasService,
        private cnpjService: CnpjService
	) {}

	ngOnInit() {
		this.cadastroForm = this.formBuilder.group({
			cnpj: ['', [Validators.required, Validators.minLength(18), Validators.maxLength(18)]],
			nomeEmpresa: ['', Validators.required],
			telefone: ['', [Validators.required, telefoneValidator]],
			nomeUsuarioCompleto: ['', Validators.required],
			email: ['', [Validators.required, Validators.email]],
			senha: ['', [Validators.required, passwordValidator]],
            senhaConfirmacao: ['', Validators.required]
        });
	}

	get passwordErrors() {
        return this.cadastroForm.get('password')?.errors?.['passwordStrength'];
    }

	get dark(): boolean {
		return this.layoutService.config.colorScheme !== 'light';
	}

	get formPessoa() {
        return this.cadastroForm.controls;
    }

	onCnpjChange(event: string) {
		const maskedValue = this.applyCnpjMask(event);
    
		if (this.cadastroForm.get('cnpj')?.value !== maskedValue) {
			this.cadastroForm.patchValue({
				cnpj: maskedValue
			}, { emitEvent: false });
		}
    }

	applyCnpjMask(value: string): string {
        value = value.replace(/\D/g, '');

        if (value.length <= 14) {
            return value.replace(
				        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
				        '$1.$2.$3/$4-$5'
				    );
        }

        return value;
    }

	togglePasswordVisibility() {
        this.showPassword = !this.showPassword;
    }

	inicialNomeMaiuscula() {
		const nomeUsuarioCompleto = this.cadastroForm.get('nomeUsuarioCompleto');
	
		if (nomeUsuarioCompleto?.value) {
			const palavras = nomeUsuarioCompleto.value
				.split(' ')
				.map((palavra: string) =>
					palavra.length > 0 
						? palavra.charAt(0).toUpperCase() + palavra.slice(1).toLowerCase() 
						: ''
				);
	
			nomeUsuarioCompleto.patchValue(palavras.join(' '), { emitEvent: false });
		}
	}

    inicialNomeEmpresaMaiuscula() {
		const nomeEmpresa = this.cadastroForm.get('nomeEmpresa');
	
		if (nomeEmpresa?.value) {
			const palavras = nomeEmpresa.value
				.split(' ')
				.map((palavra: string) =>
					palavra.length > 0 
						? palavra.charAt(0).toUpperCase() + palavra.slice(1).toLowerCase() 
						: ''
				);
	
			nomeEmpresa.patchValue(palavras.join(' '), { emitEvent: false });
		}
	}

	onSubmit() {
        this.submittedPessoa = true;
        this.loading = true;

        if (this.cadastroForm.invalid) {
            this.loading = false;
            return;
        }

        if (this.cadastroForm.value.senha !== this.cadastroForm.value.senhaConfirmacao) {
            this.senhasNaoConferem = true;
            this.loading = false;
            return;
        } else {
            this.senhasNaoConferem = false;
        }

        const cnpj = this.cadastroForm.value.cnpj.replace(/[.\-\/]/g, '');

        this.cnpjService.getCnpj(cnpj).subscribe({
            next: () => {
                this.empresasService.assinaturaCadastro14DiasGratis(this.cadastroForm.value).subscribe({
                    next: () => {
                        this.loading = false;
                        this.router.navigate(["/login"]);
                    },
                    error: (erro) => {
                        this.messageService.add({
                            key: 'tst',
                            severity: 'error',
                            summary: 'Ocorreu um erro!',
                            detail: erro,
                            life: 4000
                        });
                        this.loading = false;
                    }
                });
            },
            error: (erro) => {
                this.messageService.add({
                    key: 'tst',
                    severity: 'error',
                    summary: 'Ocorreu um erro!',
                    detail: erro,
                    life: 4000,
                });
            },
        });
    }
}
