import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class CnpjService {
    constructor(private http: HttpClient) { }

    getCnpj(cnpj: string) {
        return this.http.get<any>(`${environment.cnpjUrl}/${cnpj}`);
    }
}
