import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { first } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { LayoutService } from 'src/app/layout/service/app.layout.service';

@Component({
    templateUrl: './forgotpassword.component.html',
    providers: [MessageService]
})
export class ForgotPasswordComponent implements OnInit {
    esqueceuSenhaForm!: FormGroup;
    submitted: boolean = false;
    loading: boolean = false;

    constructor(
        private layoutService: LayoutService,
        private formBuilder: FormBuilder,
        private router: Router,
        private authenticationService: AuthenticationService,
        private messageService: MessageService
    ) {}

    ngOnInit() {
        this.esqueceuSenhaForm = this.formBuilder.group({
            email: ['', Validators.required]
        });
    }


	get dark(): boolean {
		return this.layoutService.config.colorScheme !== 'light';
	}

    get formEsqueceuSenha() { return this.esqueceuSenhaForm.controls; }

    onSubmit() {
        this.submitted = true;

        if (this.esqueceuSenhaForm.invalid) {
            return;
        }

        this.authenticationService.esqueceuSenha(this.formEsqueceuSenha['email'].value)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.loading = false;
                    this.router.navigate(["/login"]);
                },
                error: () => {
                    this.messageService.add({
                        key: 'tst',
                        severity: 'error',
                        summary: 'Ocorreu um erro!',
                        detail: 'Por favor, entre em contato com o administrador.',
                        life: 4000
                    });
                    this.loading = false;
                }
            }
        );
    }
}
